<template>
  <div class="row">
    <div
      v-for="phone in virtualPhonesPendingRemoval"
      :key="phone.id"
      class="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-xs-12 mb-4"
    >
      <virtual-phone-pending-removal-card
        :virtual-phone="phone"
        :fetch-phones="fetchPhones"
      />
    </div>
  </div>
</template>

<script>
import VirtualPhonePendingRemovalCard from '@/components/VirtualPhones/VirtualPhonePendingRemovalCard'

export default {
  name: "VirtualPhonePendingRemovalCards",
  components: { VirtualPhonePendingRemovalCard },
  props: {
    virtualPhonesPendingRemoval: {
      type: Array,
    },
    fetchPhones:  {
      type: Function,
      required: true,
    },
  },
}
</script>
