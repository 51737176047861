<template>
  <b-modal
    id="reactivate-service-window"
    ref="reactivateServiceWindow"
    title="Confirm Service Reactivation"
    size="xl"
    hide-footer
    @hidden="resetModal"
  >
    <slot v-if="loaded" name="body">
      <p>Please select the payment method you would like to use to make your payment today, {{ dateToday }}.</p>
      <payment-method-options />
      <p><i>If you would like to use a different payment method, please add the payment method to your Payment Methods under Account Settings first.</i></p>
      <p>I agree to reactivate my Phone Service for the number <b>{{ virtualPhone.number }}</b> for ${{ currentProduct.price }} per {{ pricePeriod }}.</p>
      <b-button type="submit" class="reactivate-button" variant="primary" :disabled="!submitButtonEnabled" @click="onSubmit()">
        Reactivate
      </b-button>
    </slot>
    <div v-else class="d-flex flex-row justify-content-center">
      <ct-centered-spinner label="Loading..." />
    </div>
  </b-modal>
</template>

<script>
import http from '@/http'
import { mapActions, mapGetters } from 'vuex'
import CtCenteredSpinner from '@/components/shared/CtCenteredSpinner'
import PaymentMethodOptions from '@/components/Payments/PaymentMethodOptions'

export default {
  name: 'ReactivateServiceConfirmWindow',
  components: { CtCenteredSpinner, PaymentMethodOptions },
  props: {
    virtualPhone: {
      type: Object,
      required: true,
    },
    fetchPhones:  {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      dateToday: new Date().toLocaleDateString(),
      loaded: false,
    }
  },
  computed: {
    ...mapGetters('checkout', ['agreementAccepted']),
    ...mapGetters('virtualPhones', ['selectedPhone']),
    ...mapGetters('products', ['currentProduct']),
    ...mapGetters('paymentMethods', ['isSelectedExpired', 'selectedPaymentMethod']),
    submitButtonEnabled() {
      return this.selectedPaymentMethod && Object.keys(this.selectedPaymentMethod).length !== 0 && this.agreementAccepted
    },
    pricePeriod(){
      return this.currentProduct.duration === 1 ? 'month' : 'year'
    },
  },
  async mounted () {
    await this.setSelectedVirtualPhoneById(this.virtualPhone.id)
    this.loaded = true

    this.$nextTick(() => {
      this.$refs.reactivateServiceWindow.show()
    })
  },
  methods: {
    ...mapActions('paymentMethods', ['resetPaymentMethod']),
    ...mapActions('virtualPhones', ['setSelectedVirtualPhoneById', 'setVirtualPhonesPendingRemoval']),
    successToast() {
      this.$bvToast.toast('Virtual Phone Reactivated!', {
        title: 'Success',
        variant: 'success',
        solid: true,
        autoHideDelay: 3000,
      })
    },
    failureToast(message) {
      this.$bvToast.toast(message, {
        title: 'Error',
        variant: 'danger',
        solid: true,
        autoHideDelay: 3000,
      })
    },
    async onSubmit() {
      if (this.isSelectedExpired) {
        this.failureToast('Your card is expired. Please update your card or select another payment method.')
        return false
      }

      this.loading = true
      try{
        this.$emit('changeInProcess')
        this.$emit('hideConfirmWindow')
        await http.post(`client/virtual_phones/${this.virtualPhone.id}/reactivate-virtual-phone`, {
          payable_id: this.selectedPaymentMethod.id,
        })
        this.successToast()
        await this.setVirtualPhonesPendingRemoval()

        await this.fetchPhones()
        this.loading = false
      } catch (error) {
        this.failureToast('Something went wrong, please try again later!')
        this.loading = false
      }
    },
    resetModal() {
      this.resetPaymentMethod()
      this.$emit('hideConfirmWindow')
    },
  },
}
</script>

<style lang="scss" scoped>
  ::v-deep .modal-body {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
  .reactivate-button {
    border-radius: 4px !important;
    padding: .5rem 1rem .5rem 0.625rem !important;
  }
</style>
