<template>
  <div>
    <div>
      <b-progress :value="progressValue" :variant="variantColor" striped :animated="true" class="mt-2" />
    </div>
    <div>
      {{ untilTheEndDate }}
    </div>
  </div>
</template>

<script>
import { returnDateForBrowserTimezone } from "@/common/modules/formatters"

export default {
  name: "VirtualPhoneRemovalTimer",
  props: {
    timeUntilRemoval: String,
  },
  data(){
    return {
      untilTheEndDate: '',
      distance: '',
      days: '',
      hours: '',
      minutes: '',
      seconds: '',
    }
  },

  computed: {
    progressValue() {
      return 100/45*this.days
    },
    variantColor() {
    if (this.days > 22) {
      return 'success'
    } else if (this.days <= 22 && this.days > 1 ) {
      return 'warning'
    } else if (this.days <= 1) {
      return 'danger'
    }
    },
  },

  mounted(){
    setInterval(() => {
      this.timer()
    }, 1000)
  },

  methods: {
    returnDateForBrowserTimezone: returnDateForBrowserTimezone,
    timer() {
      this.setDistance()
      this.setTimeIntervals()
      this.untilTheEndDate = `${this.days}d ${this.hours}h ${this.minutes}m ${this.seconds}s`
    },
    setTimeIntervals() {
      this.days = Math.floor(this.distance / (1000 * Math.pow(60, 2) * 24))
      this.hours = Math.floor((this.distance % (1000 * Math.pow(60, 2) * 24)) / (1000 * Math.pow(60, 2)))
      this.minutes = Math.floor((this.distance % (1000 * Math.pow(60, 2))) / (1000 * 60))
      this.seconds = Math.floor((this.distance % (1000 * 60)) / 1000)
    },
    setDistance() {
      // 45 days from the cancelled_at date.
      let endDate = this.returnDateForBrowserTimezone(this.timeUntilRemoval).ts + 3.888E9
      let now = new Date().getTime()
      this.distance = endDate - now
    },
  },
}
</script>

<style scoped>

</style>
