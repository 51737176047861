var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.suspendedPhones
    ? _c("b-container", [_c("virtual-phone-suspension-notification-modal")], 1)
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }