var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: "virtual-phone-suspension-notification-modal",
        size: "lg",
        title: "Suspension Notification",
      },
      scopedSlots: _vm._u([
        {
          key: "modal-footer",
          fn: function ({ ok, cancel }) {
            return [
              _c(
                "b-button",
                {
                  on: {
                    click: function ($event) {
                      return cancel()
                    },
                  },
                },
                [_vm._v("\n      Close\n    ")]
              ),
            ]
          },
        },
      ]),
    },
    [
      _c("p", [
        _vm._v(
          "This phone is suspended due to suspected spam activity. Please contact support."
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }