<template>
  <b-card
    :header="virtualPhone.company_name"
    header-tag="header"
    tag="article"
    style="max-width: 20rem;"
    class="text-center"
  >
    <div v-if="loading">
      <ct-centered-spinner />
    </div>
    <div v-else>
      <b-card-text>
        <p><b>{{ virtualPhone.name }}</b></p>
        <p v-if="virtualPhone.number" v-phone-number="virtualPhone.number" class="mb-3 mt-1" />
        <div class="mb-2">
          <p>Time Remaining:</p>
          <virtual-phone-removal-timer :time-until-removal="virtualPhone.cancelled_at" />
        </div>
        <b-button
          v-if="!inProcess"
          variant="primary"
          @click="openConfirmWindow"
        >
          Reactivate Phone Number
        </b-button>
        <div v-else>
          <ct-centered-spinner />
        </div>
        <reactivate-service-confirm-window
          v-if="showReactivationConfirmWindow"
          :virtual-phone="virtualPhone"
          :fetch-phones="fetchPhones"
          @hideConfirmWindow="hideConfirmWindow"
          @changeInProcess="changeInProcess"
        />
      </b-card-text>
    </div>
  </b-card>
</template>

<script>

import { phoneNumber } from '@/directives/directives'
import VirtualPhoneRemovalTimer from '@/components/VirtualPhones/VirtualPhoneRemovalTimer'
import ReactivateServiceConfirmWindow from '@/components/VirtualPhones/ReactivateServiceConfirmWindow'
import CtCenteredSpinner from '../shared/CtCenteredSpinner'
import { mapActions } from 'vuex'

export default {
  name: "VirtualPhonePendingRemovalCard",
  components: { VirtualPhoneRemovalTimer, CtCenteredSpinner, ReactivateServiceConfirmWindow },
  directives: {
    phoneNumber,
  },
  props: {
    virtualPhone:  {
      type: Object,
      required: true,
    },
    fetchPhones:  {
      type: Function,
      required: true,
    },
  },
  data(){
    return {
      currentTime: null,
      loading: false,
      inProcess: false,
      showReactivationConfirmWindow: false,
    }
  },
  methods: {
    ...mapActions('products', ['loadProductByParams']),
    async openConfirmWindow(){
      try {
        await this.loadProductByParams('virtual-phone')
        this.showReactivationConfirmWindow = true
      } catch (e) {
        // Current product was not set by loadProductParams, which is required by Reactivation Confirm Window
        this.failureToast()
      }
    },
    hideConfirmWindow(){
      this.showReactivationConfirmWindow = false
    },
    changeInProcess(){
      this.inProcess = true
    },
    failureToast(){
      this.$bvToast.toast('Something went wrong, please try again later!', {
        title: 'Error',
        variant: 'danger',
        solid: true,
        autoHideDelay: 3000,
      })
    },
  },
}
</script>
