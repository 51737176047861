<template>
  <b-modal
    id="virtual-phone-suspension-notification-modal"
    size="lg"
    title="Suspension Notification"
  >
    <p>This phone is suspended due to suspected spam activity. Please contact support.</p>
    <template #modal-footer="{ ok, cancel }" style="align-content: center">
      <b-button @click="cancel()">
        Close
      </b-button>
    </template>
  </b-modal>
</template>

<script>

export default {
  name: "VirtualPhoneSuspensionNotificationModal",
}
</script>

<style lang="scss" scoped>

</style>
