var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row" },
    _vm._l(_vm.virtualPhonesPendingRemoval, function (phone) {
      return _c(
        "div",
        {
          key: phone.id,
          staticClass: "col-xl-3 col-lg-4 col-md-6 col-sm-6 col-xs-12 mb-4",
        },
        [
          _c("virtual-phone-pending-removal-card", {
            attrs: { "virtual-phone": phone, "fetch-phones": _vm.fetchPhones },
          }),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }