var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-card",
    {
      staticClass: "text-center",
      staticStyle: { "max-width": "20rem" },
      attrs: {
        header: _vm.virtualPhone.company_name,
        "header-tag": "header",
        tag: "article",
      },
    },
    [
      _vm.loading
        ? _c("div", [_c("ct-centered-spinner")], 1)
        : _c(
            "div",
            [
              _c(
                "b-card-text",
                [
                  _c("p", [_c("b", [_vm._v(_vm._s(_vm.virtualPhone.name))])]),
                  _vm.virtualPhone.number
                    ? _c("p", {
                        directives: [
                          {
                            name: "phone-number",
                            rawName: "v-phone-number",
                            value: _vm.virtualPhone.number,
                            expression: "virtualPhone.number",
                          },
                        ],
                        staticClass: "mb-3 mt-1",
                      })
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "mb-2" },
                    [
                      _c("p", [_vm._v("Time Remaining:")]),
                      _c("virtual-phone-removal-timer", {
                        attrs: {
                          "time-until-removal": _vm.virtualPhone.cancelled_at,
                        },
                      }),
                    ],
                    1
                  ),
                  !_vm.inProcess
                    ? _c(
                        "b-button",
                        {
                          attrs: { variant: "primary" },
                          on: { click: _vm.openConfirmWindow },
                        },
                        [_vm._v("\n        Reactivate Phone Number\n      ")]
                      )
                    : _c("div", [_c("ct-centered-spinner")], 1),
                  _vm.showReactivationConfirmWindow
                    ? _c("reactivate-service-confirm-window", {
                        attrs: {
                          "virtual-phone": _vm.virtualPhone,
                          "fetch-phones": _vm.fetchPhones,
                        },
                        on: {
                          hideConfirmWindow: _vm.hideConfirmWindow,
                          changeInProcess: _vm.changeInProcess,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }