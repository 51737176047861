var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      [
        _c("b-progress", {
          staticClass: "mt-2",
          attrs: {
            value: _vm.progressValue,
            variant: _vm.variantColor,
            striped: "",
            animated: true,
          },
        }),
      ],
      1
    ),
    _c("div", [_vm._v("\n    " + _vm._s(_vm.untilTheEndDate) + "\n  ")]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }