<template>
  <b-container v-if="suspendedPhones">
    <virtual-phone-suspension-notification-modal />
  </b-container>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'

import VirtualPhoneSuspensionNotificationModal from '@/components/VirtualPhones/VirtualPhoneSuspensionNotificationModal.vue'
export default {
  name: 'CheckVirtualPhoneSuspension',
  components: { VirtualPhoneSuspensionNotificationModal },

  computed: {
    ...mapGetters('virtualPhones', ['suspendedPhones']),
  },
  async mounted() {
    await this.checkForSuspensions()
  },
  methods: {
    ...mapActions('virtualPhones', ['triggerSuspensionNotificationModalIfNeeded', 'loadSuspendedPhones']),

    async checkForSuspensions() {
      await this.loadSuspendedPhones()
      await this.triggerSuspensionNotificationModalIfNeeded(this)
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
